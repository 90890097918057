import React from 'react'
import './navbar.scss'
import {motion} from 'framer-motion'
import Sidebar from '../sidebar/Sidebar'

const Navbar = () => {
  return (
    <div className="navbar">
    <Sidebar/>
    <div className="wrapper">
    <motion.span 
    initial={{opacity:0, scale:0}}
    transition={{duration:0.5}}
    whileInView={{opacity:1, scale:1}}>Ashirbad Nayak</motion.span>
    <div className="social">
     <a href='#'><img src='/facebook.png' alt=""/></a>
     <a href='#'><img src='/instagram.png' alt=""/></a>
     <a href='#'><img src='/youtube.png' alt=""/></a>
     <a href='#'><img src='/linkedin.png' alt=""/></a>
    </div>
    </div>
    </div>
  )
}

export default Navbar